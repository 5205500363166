var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editItem != null)?_c('b-modal',{attrs:{"centered":"","title":"企业编辑","ok-title":"保存","visible":_vm.visible},on:{"hidden":_vm.onHidden}},[_c('validation-observer',{ref:"formRules"},[_c('app-collapse',[_c('app-collapse-item',{ref:"baseInfo",attrs:{"title":"基本信息","isVisible":true}},[_c('validation-provider',{attrs:{"name":"*公司名称*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"公司名称","label-for":"input-enterprise-name","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-enterprise-name","placeholder":"公司名称"},model:{value:(_vm.editItem.name),callback:function ($$v) {_vm.$set(_vm.editItem, "name", $$v)},expression:"editItem.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,91482121)}),_c('validation-provider',{attrs:{"name":"*所在行业*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"所在行业","label-for":"input-enterprise-industry","label-cols-md":"3"}},[_c('b-form-select',{attrs:{"id":"input-enterprise-industry","placeholder":"所在行业","options":_vm.industryOptions},model:{value:(_vm.editItem.industry),callback:function ($$v) {_vm.$set(_vm.editItem, "industry", $$v)},expression:"editItem.industry"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2860328486)}),_c('validation-provider',{attrs:{"name":"*联系人*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"联系人","label-for":"input-enterprise-contact-name","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-enterprise-contact-name","placeholder":"联系人"},model:{value:(_vm.editItem.contactName),callback:function ($$v) {_vm.$set(_vm.editItem, "contactName", $$v)},expression:"editItem.contactName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2068585193)}),_c('validation-provider',{attrs:{"name":"*联系电话*","rules":"required|integer|length:11"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"联系电话","label-for":"input-enterprise-contact-phone","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-enterprise-contact-phone","placeholder":"联系电话"},model:{value:(_vm.editItem.contactPhone),callback:function ($$v) {_vm.$set(_vm.editItem, "contactPhone", $$v)},expression:"editItem.contactPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,4189783218)}),_c('validation-provider',{attrs:{"name":"*邮箱*","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"邮箱","label-for":"input-enterprise-contact-mail","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-enterprise-contact-mail","placeholder":"邮箱"},model:{value:(_vm.editItem.contactEmail),callback:function ($$v) {_vm.$set(_vm.editItem, "contactEmail", $$v)},expression:"editItem.contactEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3865052034)}),_c('validation-provider',{attrs:{"name":"*企业规模*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"企业规模","label-for":"input-enterprise-scale","label-cols-md":"3"}},[_c('b-form-select',{attrs:{"id":"input-enterprise-scale","placeholder":"企业规模","options":_vm.scaleOptions},model:{value:(_vm.editItem.scale),callback:function ($$v) {_vm.$set(_vm.editItem, "scale", $$v)},expression:"editItem.scale"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,2664521446)}),_c('validation-provider',{attrs:{"name":"*管理账号*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"管理账号","label-for":"input-enterprise-admin-name","label-cols-md":"3"}},[(_vm.adminUser)?_c('div',[_c('b-avatar',{attrs:{"src":_vm.adminUser.avatar,"variant":"light-primary"}}),_c('span',[_vm._v(" "+_vm._s(_vm.adminUser.fullName)+" ("+_vm._s(_vm.adminUser.email)+")")])],1):_c('b-form-input',{attrs:{"id":"input-enterprise-admin-name","placeholder":"管理账号"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3194093358)}),(!_vm.adminUser)?_c('validation-provider',{attrs:{"name":"*管理账号密码*","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"管理账号密码","label-for":"input-enterprise-admin-pwd","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-enterprise-admin-pwd","placeholder":"管理账号密码"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3859686065)}):_vm._e()],1),_c('app-collapse-item',{attrs:{"title":"附件资料"}},[_c('b-form-group',{attrs:{"label":"附件资料","label-for":"input-enterprise-attachment","label-cols-md":"3"}},[_c('b-form-input',{attrs:{"id":"input-enterprise-attachment","placeholder":"附件资料"}})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }