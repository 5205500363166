import { render, staticRenderFns } from "./LawsEdit.vue?vue&type=template&id=10fd206d&scoped=true&"
import script from "./LawsEdit.vue?vue&type=script&lang=js&"
export * from "./LawsEdit.vue?vue&type=script&lang=js&"
import style0 from "./LawsEdit.vue?vue&type=style&index=0&id=10fd206d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../1.management/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10fd206d",
  null
  
)

export default component.exports