<template>
  <b-modal
    v-if="editItem != null"
    centered
    title="企业编辑"
    ok-title="保存"
    :visible="visible"
    @hidden="onHidden"
  >
    <validation-observer ref="formRules">
      <!-- 基本信息 -->
      <app-collapse>
        <app-collapse-item title="基本信息" ref="baseInfo" :isVisible="true">
          <validation-provider
            #default="{ errors }"
            name="*公司名称*"
            rules="required"
          >
            <b-form-group
              label="公司名称"
              label-for="input-enterprise-name"
              label-cols-md="3"
            >
              <b-form-input
                id="input-enterprise-name"
                placeholder="公司名称"
                v-model="editItem.name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*所在行业*"
            rules="required"
          >
            <b-form-group
              label="所在行业"
              label-for="input-enterprise-industry"
              label-cols-md="3"
            >
              <b-form-select
                id="input-enterprise-industry"
                placeholder="所在行业"
                v-model="editItem.industry"
                :options="industryOptions"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*联系人*"
            rules="required"
          >
            <b-form-group
              label="联系人"
              label-for="input-enterprise-contact-name"
              label-cols-md="3"
            >
              <b-form-input
                id="input-enterprise-contact-name"
                placeholder="联系人"
                v-model="editItem.contactName"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*联系电话*"
            rules="required|integer|length:11"
          >
            <b-form-group
              label="联系电话"
              label-for="input-enterprise-contact-phone"
              label-cols-md="3"
            >
              <b-form-input
                id="input-enterprise-contact-phone"
                placeholder="联系电话"
                v-model="editItem.contactPhone"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*邮箱*"
            rules="required|email"
          >
            <b-form-group
              label="邮箱"
              label-for="input-enterprise-contact-mail"
              label-cols-md="3"
            >
              <b-form-input
                id="input-enterprise-contact-mail"
                placeholder="邮箱"
                v-model="editItem.contactEmail"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*企业规模*"
            rules="required"
          >
            <b-form-group
              label="企业规模"
              label-for="input-enterprise-scale"
              label-cols-md="3"
            >
              <b-form-select
                id="input-enterprise-scale"
                placeholder="企业规模"
                v-model="editItem.scale"
                :options="scaleOptions"
              /><small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="*管理账号*"
            rules="required"
          >
            <b-form-group
              label="管理账号"
              label-for="input-enterprise-admin-name"
              label-cols-md="3"
            >
              <div v-if="adminUser">
                <b-avatar :src="adminUser.avatar" variant="light-primary" />
                <span> {{ adminUser.fullName }} ({{ adminUser.email }})</span>
              </div>
              <b-form-input
                v-else
                id="input-enterprise-admin-name"
                placeholder="管理账号"
              /><small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-if="!adminUser"
            #default="{ errors }"
            name="*管理账号密码*"
            rules="required"
          >
            <b-form-group
              label="管理账号密码"
              label-for="input-enterprise-admin-pwd"
              label-cols-md="3"
            >
              <b-form-input
                id="input-enterprise-admin-pwd"
                placeholder="管理账号密码"
              /><small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </app-collapse-item>

        <app-collapse-item title="附件资料">
          <b-form-group
            label="附件资料"
            label-for="input-enterprise-attachment"
            label-cols-md="3"
          >
            <b-form-input
              id="input-enterprise-attachment"
              placeholder="附件资料"
            />
          </b-form-group>
        </app-collapse-item>
      </app-collapse>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  email,
  confirmed,
  url,
  between,
  alpha,
  integer,
  password,
  min,
  digits,
  alphaDash,
  length,
} from "@validations";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
  },

  props: ["item", "visible"],

  data() {
    return {
      //validation
      required,
      confirmed,
      password,
      email,
      min,
      integer,
      url,
      alpha,
      between,
      digits,
      length,
      alphaDash,

      //
      editItem: null,
      adminUser: null,

      complianceOptionsClean: ["GDPR", "CCPA", "PIPL"],
      scaleOptions: ["0-10", "10-100", "100-1000", ">1000"],
      // select options
      industryOptions: [
        "互联网",
        "商业",
        "贸易",
        "制造业",
        "农业",
        "医疗",
        "物流",
      ],
    };
  },

  watch: {
    item(newVal, oldVal) {
      this.editItem = JSON.parse(JSON.stringify(this.item));
      this.loadAdminUser(this.item.adminUser);
    },

  },

  methods: {
    // load admin user info
    loadAdminUser(id) {
      if (!id) return;
      this.$http
        .get(`/api/enterprise/user/${id}`)
        .then((res) => {
          this.adminUser = res.data;
        })
        .catch((error) => {
          console.warn("Not good man :(");
        });
    },

    saveChanges() {
      this.$refs.formRules.validate().then((success) => {
        debugger;
        if (success) {
          this.$http
            .patch("/api/enterprise/" + this.currentItem.id, {
              enterprise: this.currentItem,
            })
            .then((res) => {
              this.doSearch();
            });
        }
      });
    },

    onHidden() {
      this.$emit("hidden");
    },
  },
};
</script>

<style scoped>
.inline-inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.inline-inputs > * {
  margin: 1rem 1.25rem;
}
</style>