<template>
  <b-card>
    <!-- search & new -->
    <b-row>
      <b-col cols="3">
        <b-form-select
          placeholder="应用行业"
          v-model="query_industry"
          :options="industryOptions"
        />
      </b-col>
      <b-col cols="3">
        <b-form-select
          placeholder="适应法律"
          v-model="query_compliance"
          :options="complianceOptions"
        />
      </b-col>
      <b-col cols="4">
        <b-input-group>
          <b-form-input
            type="text"
            v-model="query_keyword"
            placeholder="法律名称、标题内容"
            @keypress.enter="doSearch"
          />
          <b-input-group-append is-text>
            <feather-icon
              icon="SearchIcon"
              class="cursor-pointer"
              @click="doSearch"
            />
          </b-input-group-append>
        </b-input-group>
      </b-col>
      <b-col cols="2" class="text-right">
        <b-button variant="primary" @click="newItem"
          ><feather-icon icon="PlusIcon" class="mr-50" /><span
            class="align-middle"
            >添加内容</span
          ></b-button
        >
      </b-col>
    </b-row>

    <!-- list -->
    <b-row>
      <b-col cols="12">
        <b-table
          ref="listTable"
          selectable
          select-mode="multi"
          :items="listTableItems"
          :fields="listTableFields"
          striped
          hover
          responsive
          :per-page="listTablePerPage"
          :current-page="listTableCurrentPage"
          class="mt-1"
        >
          <template #cell(compliances)="data">
            <b-badge
              v-for="(item, idx) in data.value"
              :key="idx"
              class="mr-1"
              variant="primary"
              >{{ item }}</b-badge
            >
          </template>

          <template #cell(attachments)="data">
            <b-card-text>{{ data & data.length ? "has" : "no" }}</b-card-text>
          </template>

          <template #cell(actions)="data">
            <b-button
              variant="flat-success"
              class="btn-icon"
              @click="editItem(data)"
              ><feather-icon icon="EditIcon"
            /></b-button>
            <b-button
              variant="flat-danger"
              class="btn-icon"
              @click="deleteItem(data)"
              ><feather-icon icon="DeleteIcon"
            /></b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="listTableCurrentPage"
          :total-rows="listTableTotalRows"
          :per-page="listTablePerPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <!-- edit -->
    <LawsEdit :item="currentItem" :visible="showEdit" @hidden="onEditHidden"/>
  </b-card>
</template>


<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BFormSelect,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BTable,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInvalidFeedback,
  BAvatar,
  BFormCheckbox,
} from "bootstrap-vue";
import LawsEdit from "./LawsEdit.vue";

export default {
  name: "LawsList",

  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormSelect,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BTable,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInvalidFeedback,
    BAvatar,
    BFormCheckbox,
    LawsEdit,
  },

  data() {
    return {
      queryData: null,

      // select options
      industryOptions: [
        { value: "", text: "适用行业" },
        "全部",
        "互联网",
        "商业",
        "贸易",
        "制造业",
        "农业",
        "医疗",
        "物流",
      ],
      complianceOptions: [
        { value: "", text: "适用法律" },
        "ALL",
        "GDPR",
        "CCPA",
        "PIPL",
        "",
      ],

      // query
      query_industry: "",
      query_compliance: "",
      query_keyword: "",

      // list table
      listTableFields: [
        { key: "code", label: "编号" },
        { key: "compliances", label: "标题" },
        { key: "contactName", label: "适用法律" },
        { key: "industry", label: "适用行业" },
        { key: "contactPhone", label: "附件" },
        { key: "actions", label: "操作" },
      ],
      listTablePerPage: 5,
      listTablePageOptions: [5, 15, 30],
      listTableTotalRows: 1,
      listTableCurrentPage: 1,

      // edit
      currentItem: null,
      baseInfoVisible: true,
      adminUser: null,
      showEdit: false,
    };
  },

  computed: {
    listTableItems() {
      return this.queryData ? this.queryData.Lawss : [];
    },
  },

  methods: {
    doSearch() {
      this.$http
        .get("/api/Laws", {
          params: {
            q: this.query_keyword,
            industry: this.query_industry,
            compliance: this.query_compliance,
          },
        })
        .then((res) => {
          this.queryData = res.data;
        });
    },

    //
    editItem(data) {
      this.currentItem = JSON.parse(JSON.stringify(data.item));
      this.showEdit = true;
    },

    //
    newItem() {
      const item = {
        id: 0,
        code: null,
        name: null,
        compliances: [],
        contactName: null,
        contactPhone: null,
        contactEmail: null,
        industry: null,
        attachments: [],
        status: "active",
      };
      this.currentItem = item;
      this.adminUser = null;
      this.showEdit = true;
    },

    //
    deleteItem(data) {
      let name = data.item.name;
      this.$bvModal
        .msgBoxConfirm(`是否确定删除该条内容“ ${name}”？`, {
          title: "删除确认",
          size: "sm",
          okVariant: "primary",
          okTitle: "Yes",
          cancelTitle: "No",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          console.log(value);
          this.$http.delete(`/api/Laws/${data.item.id}`).then((res) => {
            this.$bvToast.toast(`${name} 删除成功`, {
              title: `操作成功`,
              variant: "success",
              solid: true,
            });
            this.doSearch();
          });
        });
    },

    onEditHidden(){
      this.showEdit = false
    },

  },
};
</script>